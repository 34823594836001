import {
  AppProps,
  ErrorBoundary,
  ErrorComponent,
  AuthenticationError,
  AuthorizationError,
  ErrorFallbackProps,
  useQueryErrorResetBoundary,
  Script,
} from "blitz"
import LoginForm from "app/auth/components/LoginForm"
import { ToastManager, Provider as BumbagProvider, ThemeConfig, Overlay, Spinner } from "bumbag"
import { Suspense } from "react"
import posthog from "posthog-js"
import formData from "form-data"
import { File, Blob } from "@web-std/file"

// @ts-ignore
globalThis.FormData = formData
globalThis.Blob = Blob
globalThis.File = File

if (process.env.NODE_ENV !== "development") {
  posthog.init("phc_GDIcF8T2MIpZCbUHjLLUAefS5z5yCO45blQWXMkABUK", {
    api_host: "/api/ph",
    person_profiles: "always",
  })
}

const theme: ThemeConfig = {
  palette: {
    text: "#37505C",
    primary: "#D55672",
    // info: "#1e67d5",
    // success: "#0a7d33",
    // danger: "#da1717",
    // warning: "#ed9c22",
    secondary: "#37505C",
  },
  fonts: {
    default: "Ubuntu, system-ui, sans-serif",
    heading: "Ubuntu, system-ui, sans-serif",
  },
}

export default function App({ Component, pageProps }: AppProps) {
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <>
      <Script
        dangerouslySetInnerHTML={{
          __html: `window.sa_event=window.sa_event||function(){var a=[].slice.call(arguments);window.sa_event.q?window.sa_event.q.push(a):window.sa_event.q=[a]};`,
        }}
      />
      <Script
        type="text/javascript"
        async
        defer
        dangerouslySetInnerHTML={{
          __html: `window.$crisp=[];window.CRISP_WEBSITE_ID="f03f77de-df94-4575-b5a7-947536eb053d";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`,
        }}
      />
      <Script
        async
        defer
        data-collect-dnt="true"
        src="https://simple.notionextensions.com/latest.js"
      />
      <BumbagProvider theme={theme}>
        <Suspense
          fallback={
            <Overlay visible>
              <Spinner alignX="center" />
            </Overlay>
          }
        >
          <ErrorBoundary
            FallbackComponent={RootErrorFallback}
            onReset={useQueryErrorResetBoundary().reset}
          >
            {getLayout(<Component {...pageProps} />)}
          </ErrorBoundary>
        </Suspense>
        <ToastManager />
      </BumbagProvider>
      <style jsx global>{`
        :root {
          --color: black;
        }
      `}</style>
    </>
  )
}

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  if (error instanceof AuthenticationError) {
    return <LoginForm onSuccess={resetErrorBoundary} />
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    return (
      <ErrorComponent statusCode={error.statusCode || 400} title={error.message || error.name} />
    )
  }
}
