import { BlitzPage, Routes, useRouter, Link, RouteUrlObject, useQuery } from "blitz"
import {
  Button,
  Card,
  Text,
  Image,
  Box,
  Heading,
  useToasts,
  Tag,
  Alert,
  Link as Anchor,
} from "bumbag"
import Layout from "app/core/layouts/Layout"
import { trackEvent, ExtensionTrackId } from "app/core/trackEvent"
import getCurrentUser from "app/users/queries/getCurrentUser"

const Home: BlitzPage = () => {
  const router = useRouter()
  const [user] = useQuery(getCurrentUser, null, { enabled: true, suspense: false })
  const toasts = useToasts()

  const goToExtension = (route: RouteUrlObject | string, extensionId: ExtensionTrackId) => {
    trackEvent(`${extensionId}_extension_card_clicked`)

    if (typeof route === "string") {
      window.open(route, "_blank")?.focus()
    } else {
      router.push(route)
    }
  }

  const notifyExtensionNotAvailable = (extensionId: ExtensionTrackId) => {
    trackEvent(`${extensionId}_extension_card_clicked`)
    if (user) {
      toasts.add({
        title: "Extension not available yet",
        message: `We'll let you know when it's ready!`,
        hasIcon: false,
        accent: "bottom",
      })
    } else {
      router.push(Routes.LoginPage())
    }
  }

  return (
    <>
      <Box flex={1} alignX="center" marginBottom="3rem">
        <Heading use="h1" textAlign="center">
          Your Notion workspace,
          <br />
          even more powerful.
        </Heading>
        <Text fontSize="250" textAlign="center">
          Small & configurable extensions that make you more productive on notion.so
        </Text>
      </Box>
      <Box flex={1}>
        <Link shallow href={Routes.GoogleCalendarExtensionPage()}>
          <a style={{ textDecoration: "none", color: "inherit" }}>
            <Card
              onClick={() => trackEvent(`google_calendar_extension_card_clicked`)}
              standalone
              marginTop="2rem"
              textAlign="center"
            >
              <Image alt="" src="/gcalendar.gif" maxHeight="148px" />
              <Card.Title>Google calendar</Card.Title>
              Sync your Google Calendar events with Notion, and vice-versa.
            </Card>
          </a>
        </Link>
        {/* <Card
          style={{ cursor: "pointer" }}
          onClick={() =>
            goToExtension(
              "https://chrome.google.com/webstore/detail/kngfbhmaimflbboiolphlbdbippdglbl?authuser=0&hl=en-GB",
              "sidebar"
            )
          }
          standalone
          textAlign="center"
          position="relative"
        >
          <Tag
            palette="primary"
            variant="tint"
            position="absolute"
            right="1rem"
            top="1rem"
            size="medium"
          >
            BETA
          </Tag>
          <video
            controls={false}
            loop
            autoPlay
            src="/sidebar_demo.mp4#t=11"
            style={{ maxHeight: "148px", maxWidth: "100%" }}
          ></video>
          <Card.Title>Sidebar</Card.Title>
          With this new browser extension, you will be able to use NotionExtensions as a sidebar
          whenever you visit your notion.so workspace.
        </Card> */}

        <Link href={Routes.ChartsExtensionPage()}>
          <a style={{ textDecoration: "none", color: "inherit" }}>
            <Card
              onClick={() => trackEvent("charts_extension_card_clicked")}
              standalone
              textAlign="center"
              marginTop="2rem"
              position="relative"
            >
              <Tag
                palette="primary"
                variant="tint"
                position="absolute"
                right="1rem"
                top="1rem"
                size="medium"
              >
                BETA
              </Tag>
              <Image
                alt="Charts extension"
                src="/demo_charts.gif"
                maxHeight="148px"
                maxWidth="100%"
              />
              <Card.Title>Charts</Card.Title>
              Generate embeddable charts from your Notion databases.
            </Card>
          </a>
        </Link>
        <Link href={Routes.RecurringEventsExtensionPage()}>
          <a style={{ textDecoration: "none", color: "inherit" }}>
            <Card
              onClick={() => trackEvent("recurring_events_extension_card_clicked")}
              standalone
              marginTop="2rem"
              textAlign="center"
            >
              <Image alt="" src="/demo_recurring_events.gif" width="100%" />
              <Card.Title>Recurring events</Card.Title>
              Repeat any page over time.
            </Card>
          </a>
        </Link>
        <Link href={Routes.AutoIncrementPropertyExtensionPage()}>
          <a style={{ textDecoration: "none", color: "inherit" }}>
            <Card
              onClick={() => trackEvent("auto_increment_property_extension_card_clicked")}
              standalone
              marginTop="2rem"
              textAlign="center"
            >
              <Image alt="" src="/demo_autoincrement2.gif" width="100%" />
              <Card.Title>Auto increment property</Card.Title>
              Automatically increment a numeric property of any new page in a database.
            </Card>
          </a>
        </Link>
        <Link href={Routes.AutomaticPageCreationExtensionPage()}>
          <a style={{ textDecoration: "none", color: "inherit" }}>
            <Card
              onClick={() => trackEvent("auto_page_creation_extension_card_clicked")}
              standalone
              marginTop="2rem"
              textAlign="center"
            >
              <Image alt="" src="/demo_autopagecreation.gif" width="100%" />
              <Card.Title>Automatic page creation</Card.Title>
              Schedule the creation of a page within a database. Useful for things like recurring
              tasks, habit trackers, journals...
            </Card>
          </a>
        </Link>
        <Card
          style={{ cursor: "pointer" }}
          onClick={() => {
            notifyExtensionNotAvailable("apple_calendar")
          }}
          standalone
          marginTop="2rem"
          textAlign="center"
        >
          <Image alt="" src="/applecalendar.gif" maxHeight="148px" />
          <Card.Title>Apple calendar</Card.Title>
          Sync your Apple Calendar with Notion, and vice-versa.
        </Card>
        <Card
          style={{ cursor: "pointer" }}
          onClick={() => {
            notifyExtensionNotAvailable("github")
          }}
          standalone
          marginTop="2rem"
          textAlign="center"
        >
          <Image alt="" src="/github.gif" maxHeight="148px" />
          <Card.Title>Github</Card.Title>
          Sync related commits, branches, pull requests, builds and deployments with Notion.
        </Card>
      </Box>
      {!user && (
        <Box
          position="sticky"
          bottom="0"
          paddingBottom="1rem"
          paddingTop="1rem"
          background="white"
          alignX="center"
        >
          <Link href={Routes.SignupPage()}>
            <Button
              palette="primary"
              color="white"
              onClick={() => trackEvent("try_it_now_button_clicked")}
            >
              Try it now - It&apos;s free
            </Button>
          </Link>
        </Box>
      )}
      <Alert variant="tint" type="info" hasIcon={false} textAlign="center" marginTop="2rem">
        <div>
          <b>Looking for a QA Job?</b>
        </div>
        <Anchor target="_blank" href="https://www.qajobs.co?ref=notionextensions.com">
          QAjobs.co
        </Anchor>{" "}
        is the premier job board for Quality Assurance, SDET, and all other Test Automation Engineer
        opportunities
      </Alert>
    </>
  )
}

Home.suppressFirstRenderFlicker = true
Home.getLayout = (page) => <Layout>{page}</Layout>

export async function getStaticProps() {
  return {
    props: {},
  }
}

export default Home
