import { ReactNode, Suspense } from "react"
import { Head, Link, Routes } from "blitz"
import { useCurrentUser } from "../hooks/useCurrentUser"
import {
  Divider,
  Overlay,
  Spinner,
  TopNav,
  Button,
  PageWithHeader,
  PageContent,
  Image,
  useBreakpoint,
  Icon,
  DropdownMenu,
  Link as Anchor,
} from "bumbag"
import { trackEvent } from "../trackEvent"
import { faBars } from "@fortawesome/free-solid-svg-icons"

type LayoutProps = {
  title?: string
  description?: string
  children: ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <PageWithHeader
      sticky
      overrides={{
        PageWithHeader: {
          Header: {
            styles: {
              base: {
                borderBottom: "0px",
              },
            },
          },
        },
      }}
      header={
        <TopNav selectedId="some-nonvalid-section" maxWidth="1024px" margin="0 auto">
          <TopNav.Section>
            <Link href={Routes.Home()}>
              <Image
                alt="NotionExtensions"
                src="/logo_s.png"
                height="100%"
                padding="0.5rem"
                cursor="pointer"
              />
            </Link>
          </TopNav.Section>
          <Suspense fallback={<Spinner alignX="center" width="287px" alignY="center" />}>
            <RightSection />
          </Suspense>
        </TopNav>
      }
    >
      <PageContent minHeight="93vh">{children}</PageContent>
      <Divider maxWidth="1024px" margin="0 auto" />

      <footer
        style={{
          maxWidth: "1024px",
          margin: "0 auto",
          padding: "30px 1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Anchor
          color="text"
          target="_blank"
          href="https://brais.notion.site/What-s-new-NotionExtensions-85cd31e7870b40bbb26bbdb3a0c985c8"
        >
          What&apos;s new
        </Anchor>
        <Anchor
          color="text"
          target="_blank"
          href="https://brais.notion.site/FAQs-NotionExtensions-3f31e71dd9de4758ad02a1d32db775a0"
        >
          FAQs
        </Anchor>
        <Link href={Routes.PricingPage()}>
          <Anchor color="text">Pricing</Anchor>
        </Link>
        <Link href={Routes.PrivacyPolicyPage()}>
          <Anchor color="text">Privacy policy</Anchor>
        </Link>
      </footer>
    </PageWithHeader>
  )
}

const LayoutWrapper = (props: LayoutProps) => {
  const finalTitle = props.title ? `${props.title} - NotionExtensions` : "NotionExtensions"
  const defaultDescription =
    "Two-way sync between Google Calendar and Notion, dynamic charts, and other tools to supercharge your Notion workspace while boosting productivity!"
  const description = props.description ? props.description : defaultDescription
  const image = `${process.env.PUBLIC_URL}/og_image.png`

  return (
    <>
      <Head>
        <title>{finalTitle}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={description} />
        <meta property="og:title" content={finalTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        <meta property="twitter:title" content={finalTitle} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={image} />
      </Head>
      <Suspense
        fallback={
          <Overlay visible>
            <Spinner alignX="center" />
          </Overlay>
        }
      >
        <Layout {...props} />
      </Suspense>
    </>
  )
}

export default LayoutWrapper

const RightSection: React.FC = () => {
  const isScreenDesktopWide = useBreakpoint("min-desktop")
  const user = useCurrentUser()

  const items = [
    user && { name: "Settings", href: Routes.UserSettingsPage() },
    { name: "Contact", href: "mailto:brais@notionextensions.com" },
    user && {
      name: "Log out",
      href: Routes.Logout(),
      buttonProps: {
        palette: "primary",
      },
    },
    !user && {
      name: "Log in",
      href: Routes.LoginPage(),
      buttonProps: {
        palette: "primary",
      },
      props: {
        onClick: () => {
          trackEvent("login_button_clicked")
        },
      },
    },
    !user && {
      name: "Sign up",
      href: Routes.SignupPage(),
      buttonProps: {
        palette: "primary",
      },
      props: {
        onClick: () => {
          trackEvent("signup_button_clicked")
        },
      },
    },
  ]

  if (isScreenDesktopWide) {
    return (
      <TopNav.Section>
        {items.map((item) =>
          item ? (
            <Link href={item.href} key={item.name}>
              <Button variant="link" {...(item.buttonProps || {})} {...(item.props || {})}>
                {item.name}
              </Button>
            </Link>
          ) : null
        )}
      </TopNav.Section>
    )
  }

  return (
    <TopNav.Section>
      <TopNav.Item>
        <DropdownMenu
          menu={
            <>
              {items.map((item) =>
                item ? (
                  <Link key={item.name} href={item.href}>
                    <DropdownMenu.Item {...(item.props || {})}>{item.name}</DropdownMenu.Item>
                  </Link>
                ) : null
              )}
            </>
          }
        >
          <Button variant="ghost" marginRight="0.25rem" color="text">
            <Icon aria-label="Menu" fontSize="1.5rem" type="font-awesome" icon={faBars} />
          </Button>
        </DropdownMenu>
      </TopNav.Item>
    </TopNav.Section>
  )
}
